










































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import { PostBookContent, GetBookMulu } from "@/request/storehouse";
import { BookMuluText } from "@/utils/base";
import Book from "@/components/book.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    Book,
  },
})
export default class Name extends Vue {
  private source: any = "";
  private fontSize: any = 16;
  private title: any = "";
  private id: any = "";
  private book_id: any;
  private page: any = "";
  private currentZhangjie: any = "";
  private data: any = {};
  private navs: any = [];
  private currentNode: any = {};
  private defaultProps: any = {
    label: "章节名",
  };
  private expandedNode: any = [];
  private readData: any = {};
  private refreshTime: any = "";
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private goMulu() {
    if (this.page == "search") {
      this.$router.push({
        path: "/main/storehouse/mulu",
        query: {
          id: this.data.book_id,
        },
      });
    } else {
      this.$router.back();
    }
  }
  private getData() {
    const params: any = {
      params: {
        title_id: this.currentNode._id,
      },
    };
    PostBookContent(this, params).then((res: any) => {
      this.data = res;
      if (!this.currentZhangjie) {
        this.data.titles.forEach((ele: any, index: any) => {
          if (this.id == ele._id) {
            this.currentZhangjie = ele;
            this.currentZhangjie.index = index;
          }
        });
      }
    });
  }
  private goBefore() {
    if (this.currentZhangjie.index === 0) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index - 1;
    this.id = this.data.titles[index]._id;
    this.currentZhangjie = this.data.titles[index];
    this.currentZhangjie.index = index;
    this.getData();
  }
  private goNext() {
    if (this.currentZhangjie.index === this.data.titles.length - 1) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index + 1;
    this.id = this.data.titles[index]._id;
    this.currentZhangjie = this.data.titles[index];
    this.currentZhangjie.index = index;
    this.getData();
  }
  private handleNodeClick(data: any, node: any) {
    this.currentNode = data;
    this.init();
  }
  private init() {
    if (this.$refs.tree) {
      setTimeout(() => {
        (this.$refs.tree as any).setCurrentKey(this.currentNode);
      }, 300);
      this.expandedNode = [this.currentNode["_id"]];
      this.getData();
    }
  }
  private getMulu() {
    const params: any = {
      params: {
        book_id: this.book_id,
      },
    };
    GetBookMulu(this, params).then((res: any) => {
      this.navs = res;
      if (this.id) {
        this.getData();
      } else {
        this.currentNode = res.titles[0];
        this.init();
      }
    });
  }
  private mounted() {
    this.book_id = this.$route.query.book_id;
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.source = this.$route.query.source;
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.readData = {
      book_id: this.book_id,
    };
    this.refreshTime = new Date().getTime();
    // this.getMulu();
  }
}
